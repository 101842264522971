.memorycard {
	position: relative;
	height: 264px;
}

.openCard {
	transform: scaleY(-0.2);
}

.resolved {
	opacity: 50%;
}
