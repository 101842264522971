@keyframes bar {
	0% {
		width: 100%;
		background-color: #0eaba9;
	}
	75% {
		background-color: #1781e3;
	}
	50% {
		background-color: #5944c6;
	}
	75% {
		background-color: #a63297;
	}
	100% {
		background-color: #e61e64;
		width: 0;
	}
}

.bar {
	height: 16px;
	width: 100%;
	background-color: #dde3e7;
	position: absolute;
	left: 50%;
	top: 50%;
}
.animated {
	border-radius: 0 8px 8px 0;
	animation: bar 45s linear forwards;
}
