p,
h1,
body {
	color: #001155;
}

h1 {
	font-size: 48px;
}

p {
	font-size: 22px;
	line-height: 36px;
}

.bigButton sdx-button {
	scale: 2.5;
	transform: translateY(52px);
}
